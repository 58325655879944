import axios from "axios";
import { Link, Navigate, useParams } from "react-router-dom";
import {useState,useRef,useEffect, useCallback, useMemo} from 'react';
import { useAuth } from './use-auth';
import { useDropzone } from 'react-dropzone';

const baseStyle = {
    display: "flex",
    flexDirection: "column",
    width: 200,
    height: 150,
};
const borderNormalStyle = {
    border: "1px dotted #888"
};
const borderDragStyle = {
    border: "1px solid #00f",
    transition: 'border .5s ease-in-out'
};

const mybucket = 'o-yasumi-share';

// アップロード用の署名付きURLの発行を要求する
// param には Bucket: (バケット名) Key: (ファイルパス) を入れる
export async function get_upload_presignedurl(headerdata, param) {
 return await axios.post('/api/upload_presignedurl/' + param.Bucket , param, { headers : headerdata }).then( (response) => {
  console.log("get_upload_presignedurl response : " + JSON.stringify(response.data));
  return response;
 })
 .catch((err) => {
  console.log("get_upload_presignedurl err : " + JSON.stringify(err));
  throw err;
 });
}

// prefix 内のリストを表示
// param には Bucket: (バケット名) Prefix: (prefix) を入れる
export async function list_folder(headerdata, param) {
 return await axios.get('/api/list_folder/' + param.Bucket + '/' + param.Prefix,  { headers : headerdata }).then( (response) => {
  console.log("list_folder response : " + JSON.stringify(response.data));
  return response;
 })
 .catch((err) => {
  console.log("list_folder err : " + JSON.stringify(err));
  throw err;
 });
}

// ダウンロード用の署名付きURLの発行を要求する
// param には Bucket: (バケット名) Public: (公開する場合は1) Key: (ファイルパス) を入れる
export async function get_download_presignedurl(headerdata, param) {
 return await axios.get('api/download_presignedurl/' + param.Bucket + '/' + param.Public + '/' + param.Key, { headers : headerdata }).then( (response) => {
  console.log("get_download_presignedurl response : " + JSON.stringify(response.data));
  return response;
 })
 .catch((err) => {
  console.log("get_download_presignedurl err : " + JSON.stringify(err));
  throw err;
 });
}
 
// 削除を要求する
// param には Bucket: (バケット名) Type: (フォルダの場合は'dir'、ファイルの場合は'file') Key: (パス) を入れる
export async function delete_object(headerdata, param) {
 return await axios.delete('api/delete_object/' + param.Bucket + '/' + param.Type + '/' + param.Key, { headers : headerdata }).then( (response) => {
  console.log("delete_object response : " + JSON.stringify(response.data));
  return response;
 })
 .catch((err) => {
  console.log("get_download_presignedurl err : " + JSON.stringify(err));
  throw err;
 });
}

const Sharefolder = () => {

    const auth = useAuth();
    const headerdata = {
     "Authorization": "Bearer " + auth.jwtToken
    };

    
    const [pwd, setpwd] = useState('');
    // 現在の pwd 直下のフォルダ・ファイルを入れる
    const [subdirs, setsubdirs] = useState([]);
    const [subfiles, setsubfiles] = useState([]);

    // 公開リンクを入れる
    const [publicurl, setpublicurl] = useState();

    // アップロード状況を入れる
    const [statusarr, setstatusarr] = useState([]);

    const baseStyle = {
        display: "flex",
        flexDirection: "column",
        width: 200,
        height: 150,
    };
    const borderNormalStyle = {
        border: "1px dotted #888"
    };
    const borderDragStyle = {
        border: "1px solid #00f",
        transition: 'border .5s ease-in-out'
    };

    // 公開URL表示用のダイアログ https://route360.dev/ja/post/dialog-react/
    const dialog=useRef();
      const closeHandler = () => {
      dialog.current.close()
    }

    useEffect(() => {
      auth.refresh_token().then( (data) => {
       return list_folder(headerdata, { Bucket : mybucket, Prefix : '/' + pwd } );
      }).then( (response) => {
       let new_subdirs = [];
       // 配下ディレクトリの配列は response.data.res.CommonPrefixes
       if(typeof response.data.res.CommonPrefixes !== 'undefined') {
        new_subdirs=response.data.res.CommonPrefixes;
        // pwd を除いた相対フォルダ名を追加 https://mai.kosodante.com/expects-a-return-value-from-arrow-function-array-callback-return/
        new_subdirs.forEach( (item) =>  {
         const ptn=new RegExp(`^${pwd}`);
         item.subdir = item.Prefix.replace(ptn,"");
        });
       }
       else {
        new_subdirs=[];
       }
       setsubdirs(new_subdirs);
       return(response);
      }).then( (response) => {
       let new_subfiles = new Array();
       // 配下ファイルの配列は response.data.res.Contents
       if(typeof response.data.res.Contents !== 'undefined') {
        new_subfiles=response.data.res.Contents;
        // pwd を除いたファイル名を追加 https://mai.kosodante.com/expects-a-return-value-from-arrow-function-array-callback-return/
        new_subfiles.forEach( (item) =>  {
         const ptn=new RegExp(`^${pwd}`);
         item.filename = item.Key.replace(ptn,"");
        });
       }
       else {
        new_subfiles=[];
       }
       setsubfiles(new_subfiles);
      }).catch((err) => {
        if(err.response.status === 401) {
         alert("セッションが無効になりました。一度ログアウトして、再度ログインしてください。");
        }
        else {
         alert("エラーのため表示できませんでした。");
        }
      });
      // https://zenn.dev/mackay/articles/1e8fcce329336d
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[pwd, statusarr])

    const onDrop = useCallback((acceptedFiles) => {
        // Do something with the files
        // alert('ファイルをアップロードします。カレントフォルダは ' + pwd + ' です');
        setstatusarr([]);
        acceptedFiles.forEach((file) => {
            // alert('ファイル: ' + JSON.stringify(file) + " ファイルパス : " + file.path + " アップロードパス : " + pwd + file.path);
            // アップロード用の署名付きURL発行を要求する
            const param = { Bucket : mybucket, Key : pwd + file.path };
            setstatusarr( statusarr => [...statusarr, { path: file.path, mes: "のアップロードを開始します。" }]);
            get_upload_presignedurl(headerdata, param).then( (response) => {
                console.log(file.path + ' の署名付きURL発行完了。 response : ' + JSON.stringify(response));
                setstatusarr( statusarr => [...statusarr.filter( k => k.path != file.path ), { path: file.path, mes: "のアップロードをしています。" }]);
                return axios.put(response.data.url, file);
            }).then((response) => {
                console.log(file.path + ' のアップロード完了。 response : ' + JSON.stringify(response));
                setstatusarr( statusarr => [...statusarr.filter( k => k.path != file.path ), { path: file.path, mes: "のアップロードが完了しました。" }]);
                return list_folder(headerdata, { Bucket : mybucket, Prefix : '/' + pwd } );
            }).then( (response) => {
                // file.path に '/' が含まれている場合はフォルダ一覧を更新。含まれていなければファイル一覧を更新
                if( file.path.indexOf('/') !== -1 ) {
                 // '/' が含まれる
                 let new_subdirs = [];
                 // 配下ディレクトリの配列は response.data.res.CommonPrefixes
                 if(typeof response.data.res.CommonPrefixes !== 'undefined') {
                  new_subdirs=response.data.res.CommonPrefixes;
                  // pwd を除いた相対フォルダ名を追加
                  new_subdirs.forEach( (item) =>  {
                   const ptn=new RegExp(`^${pwd}`);
                   item.subdir = item.Prefix.replace(ptn,"");
                  });
                 }
                 else {
                  new_subdirs=[];
                 }
                 setsubdirs(new_subdirs);
                }
                else {
                 // '/' が含まれない
                 let new_subfiles = [];
                 // 配下ファイルの配列は response.data.res.Contents
                 if(typeof response.data.res.Contents !== 'undefined') {
                  new_subfiles=response.data.res.Contents;
                  // pwd を除いたファイル名を追加
                  new_subfiles.forEach( (item) =>  {
                   const ptn=new RegExp(`^${pwd}`);
                   item.filename = item.Key.replace(ptn,"");
                  });
                 }
                 else {
                  new_subfiles=[];
                 }
                 setsubfiles(new_subfiles);
                }
            }).catch((err) => {
                console.log(file.path + ' のアップロード失敗。 err : ' + JSON.stringify(err));
                if(err.response.status === 401) {
                    alert("セッションが無効になりました。一度ログアウトして、再度ログインしてください。");
                }
                else {
                    alert("エラーのため登録できませんでした。");
               }
            });
            // alert("次へ");
        });

        // https://zenn.dev/mackay/articles/1e8fcce329336d
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pwd]);

    const { getRootProps, getInputProps, isDragActive, open, acceptedFiles } = useDropzone({
        onDrop,
        noClick: true
    });
    const style = useMemo(() => (
        { ...baseStyle, ...(isDragActive ? borderDragStyle : borderNormalStyle)}
    ), [isDragActive]);
    const files = useMemo(() => 
        acceptedFiles.map((file) => (
            <li key={file.path}>
                name : {file.name} path : {file.path} - {file.size} bytes keys : {Object.keys(file)}
            </li>
        )
    ), [acceptedFiles]);

    const chdir = (e) => {
     const path = e.target.getAttribute("path");
     setstatusarr([]);
     if( path == "/" ) {
      // トップフォルダへ移動の場合
      setpwd('');
     }
     else {
      setpwd(path);
     }
    }

    const download = (e) => {
     alert(e.target.getAttribute("path") + ' をダウンロード');
     setstatusarr([]);
     // ダウンロード用の署名付きURL発行を要求する。公開はしない
     const param = { Bucket : mybucket, Public : 0, Key : e.target.getAttribute("path") };
     get_download_presignedurl(headerdata, param).then( (response) => {
      console.log(e.target.getAttribute("path") + ' の署名付きURL発行完了。 response : ' + JSON.stringify(response));
      // https://tech.uzabase.com/entry/2023/07/14/084722
      const link = document.createElement('a');
      link.setAttribute('href', response.data.url);
      link.setAttribute('download', e.target.getAttribute("filename"));
      link.click();
      link.remove();
      URL.revokeObjectURL(response.data.url);
     }).catch((err) => {
      console.log(e.target.getAttribute("path") + ' のダウンロード失敗。 err : ' + JSON.stringify(err));
      if(err.response.status === 401) {
       alert("セッションが無効になりました。一度ログアウトして、再度ログインしてください。");
      }
      else {
       alert("エラーのためダウンロードできませんでした。");
      }
     });
    }

    const makepublicurl = (e: ChangeEvent<HTMLInputElement>) => {
     // 公開用の署名付きURL発行を要求する
     const param = { Bucket : mybucket, Public : 1, Key : e.target.getAttribute("path") };
     get_download_presignedurl(headerdata, param).then( (response) => {
      console.log(e.target.getAttribute("path") + ' の署名付きURL発行完了。 response : ' + JSON.stringify(response));
      setpublicurl(response.data.url);
      return(response);
     }).then((response) => {
      // ダイアログに表示
      dialog.current.showModal()
     }).catch((err) => {
      console.log(e.target.getAttribute("path") + ' の公開リンク作成失敗。 err : ' + JSON.stringify(err));
      if(err.response.status === 401) {
       alert("セッションが無効になりました。一度ログアウトして、再度ログインしてください。");
      }
      else {
       alert("エラーのためダウンロードできませんでした。");
      }
     });
    }

    const delete_obj = (e: ChangeEvent<HTMLInputElement>) => {
     // 削除を要求する
     let mes;
     const target_type = e.target.getAttribute("type");
     const target_path = e.target.getAttribute("path");
     const target_name = e.target.getAttribute("name");
     if(target_type == 'file') {
      mes=target_name + ' を削除しますか?';
     }
     else {
      mes=target_name + ' 配下をすべて削除します。本当に削除しますか?';
     }
     if(window.confirm(mes)) {
      setstatusarr([]);
      const param = { Bucket : mybucket, Type : target_type, Key : target_path };
      delete_object(headerdata, param).then( (response) => {
       console.log(target_path + ' の削除完了。 response : ' + JSON.stringify(response));
       return list_folder(headerdata, { Bucket : mybucket, Prefix : '/' + pwd } );
      }).then( (response) => {
       // type が 'dir' の場合はフォルダ一覧を更新。'file' の場合はファイル一覧を更新
       if( param.Type == 'dir' ) {
        let new_subdirs = new Array();
        // 配下ディレクトリの配列は response.data.res.CommonPrefixes
        if(typeof response.data.res.CommonPrefixes !== 'undefined') {
         new_subdirs=response.data.res.CommonPrefixes;
         // pwd を除いた相対フォルダ名を追加
         new_subdirs.forEach( (item) =>  {
          const ptn=new RegExp(`^${pwd}`);
          item.subdir = item.Prefix.replace(ptn,"");
         });
        }
        else {
         new_subdirs=[];
        }
        setsubdirs(new_subdirs);
       }
       else {
        let new_subfiles = [];
        // 配下ファイルの配列は response.data.res.Contents
        if(typeof response.data.res.Contents !== 'undefined') {
         new_subfiles=response.data.res.Contents;
         // pwd を除いたファイル名を追加
         new_subfiles.forEach( (item) =>  {
          const ptn=new RegExp(`^${pwd}`);
          item.filename = item.Key.replace(ptn,"");
         });
        }
        else {
         new_subfiles=[];
        }
        setsubfiles(new_subfiles);
       }
       return(response);
      }).then( (response) => {
       alert(target_name + ' を削除しました。');
      }).catch((err) => {
       console.log(target_path + ' の削除失敗。 err : ' + JSON.stringify(err));
       if(err.response.status === 401) {
        alert("セッションが無効になりました。一度ログアウトして、再度ログインしてください。");
       }
       else {
        alert("エラーのため削除できませんでした。");
       }
      });
     }
    }

    const copytext = async (e: ChangeEvent<HTMLInputElement>) => {
     // コピー https://blog.usize-tech.com/react-copy-clipboard-button/
     await global.navigator.clipboard.writeText(e.target.getAttribute("copytext"));
    } 

  // パンくずリスト用
  let cur_path='';

  return (
       <div>
        <dialog ref={dialog}>
         <p>公開リンクは以下です。有効期限は1週間です。</p>
         <p><button onClick={copytext} copytext={publicurl}>公開リンクをコピー</button></p>
         <p>{publicurl}</p>
         <button onClick={closeHandler} type="button">閉じる</button>
        </dialog>
        <h1>共有フォルダ</h1>
        { auth.isAuthenticated ? <p>認証済み</p> : <Navigate to="/signin" /> }

        <p>
        <span><Link onClick={chdir} path="/">トップ</Link> > </span>
        {pwd.split('/').map( (dir, index) => {
          if(dir) {
           cur_path += dir + '/';
           return (
            <span><Link onClick={chdir} path={cur_path}>{dir}</Link> > </span>
           );
          }
        })}
        </p>
        <h2>フォルダ</h2>
        {subdirs.map(item => (
          <p><Link onClick={chdir} path={item.Prefix}>{item.subdir}</Link><button onClick={delete_obj} type="dir" path={item.Prefix} name={item.subdir}>このフォルダ配下をすべて削除</button></p>
        ))}
        <h2>ファイル</h2>
        {subfiles.map(item => (
          <p><Link onClick={download} path={item.Key} filename={item.filename}>{item.filename} ({item.Size} byte)</Link><button onClick={makepublicurl} path={item.Key}>公開リンクを作成</button><button onClick={delete_obj} type="file" path={item.Key} name={item.filename}>削除</button></p>
        ))}
         <div className="container">
            <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p>ここにドロップしてアップロード</p> :
                        <p>ここにドラッグしてアップロード</p>
                }
                <button type="button" onClick={open} className="btn btn-primary align-self-center">ファイルを選択</button>
            </div>
        </div>
        {statusarr.map(item => (
          <p>{item.path} {item.mes}</p>
        ))}
        <div>
         <Link to={`/`}>ホームに戻る</Link>
        </div>
       </div>
  );
};


export default Sharefolder;


