import React from 'react';
import {useState,useRef,useEffect} from 'react';

import {useDraggable} from '@dnd-kit/core';
import { Board } from "./Board";

import {AttrDialog} from './AttrDialog';


export const BookmarkItem = ({ key, id, type, url, name, parentid, numindir, childclick }) => {

  const [isdiropen, setisdiropen] = useState(false);
  const [isurlopen, setisurlopen] = useState(false);

  const openDialog = (e: ChangeEvent<HTMLInputElement>) => {
   if( e.target.getAttribute("type") == "dir" ) {
    setisdiropen(true);
   }
   else if( e.target.getAttribute("type") == "url" ) {
    setisurlopen(true);
   }
  }

  const closeDialog = ( param: any) => {
   setisdiropen(false);
   setisurlopen(false);
   if( param ) {
    childclick(JSON.stringify(param));
   }
   // else {
   //  alert("ダイアログが閉じられました。 param : " + JSON.stringify(param));
   // }
  }


   const del_dir = (e: ChangeEvent<HTMLInputElement>) => {
    if(window.confirm('フォルダ配下のサイトも削除されます。本当に削除しますか?')) {
     const param = { method : "DELETE", type : "dir", id : e.target.getAttribute("delid") };
     childclick(JSON.stringify(param));
     // childclick("フォルダを削除します。 id : " + e.target.getAttribute("delid"));
    }
   }
   const del_url = (e: ChangeEvent<HTMLInputElement>) => {
    if(window.confirm('本当に削除しますか?')) {
     const param = { method : "DELETE", type : "url", id : e.target.getAttribute("delid") };
     childclick(JSON.stringify(param));
     // childclick("サイトを削除します。 id : " + e.target.getAttribute("delid"));
    }
   }


  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: id
  });

  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    display: 'inline-block',
    _display: 'inline'
  } : {
    display: 'inline-block',
    _display: 'inline'
  };

  return (
   <Board id={id}>
     { type == 'url' ? <div id={id} className={type} href={url} ref={setNodeRef} {...attributes} {...listeners} style={style}><img src="symbol095.png" width="20" height="20" /><a href={url} target="_blank" rel="noopener noreferrer">{name}</a>{/* (id: {id} parentid: {parentid} numindir: {numindir} ) */}</div>
      : <div id={id} className={type} ref={setNodeRef} {...attributes} {...listeners} style={style}><img src="symbol022.png" width="20" height="20" />{name}{/* (id: {id} parentid: {parentid} numindir: {numindir} ) */}</div> }
     { type == 'url' ? <div style={style}><button onClick={openDialog} type={type} >名前・URL変更</button><button className="exec" onClick={del_url} delid={id} >サイト削除</button><AttrDialog isOpen={isurlopen} onClose={closeDialog} method='PUT' type='url' modid={id} cur_name={name} cur_url={url} /></div>
      : <div style={style}><button onClick={openDialog} type={type} >名前変更</button><button className="exec" onClick={del_dir} delid={id} >フォルダ削除</button><AttrDialog isOpen={isdiropen} onClose={closeDialog} method='PUT' type='dir' modid={id} cur_name={name} /></div> }
   </Board>
  )
}







