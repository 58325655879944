import axios from "axios";
import { Link, Navigate, useParams } from "react-router-dom";
import {useState,useRef,useEffect, useCallback, useMemo} from 'react';
import { useAuth } from './use-auth';
import {ScheduleEventDialog} from './ScheduleEventDialog';

import FullCalendar, { EventClickArg, EventContentArg } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list'; // 追加
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import jaLocale from '@fullcalendar/core/locales/ja';
import './App.css';

const renderEventContent = (eventInfo: EventContentArg) => (
	<>
		<b>{eventInfo.timeText}</b>
		<i>{eventInfo.event.title}</i>
	</>
);

async function get_schedule(headerdata) {
 // const httpsAgent = new https.Agent({ rejectUnauthorized: false });
 return await axios.get('/api/schedule', { headers : headerdata }).then( (response) => {
  // console.log("response : " + JSON.stringify(response.data));
  return response;
 })
 .catch((err) => {
  // console.log(err);
  throw err;
 });
}

async function post_schedule(headerdata, param) {
 return await axios.post('/api/schedule', param, { headers : headerdata }).then( (response) => {
  // console.log("response : " + JSON.stringify(response.data));
  return response;
 })
 .catch((err) => {
  // console.log(err);
  throw err;
 });
}

async function put_schedule(headerdata, param) {
 return await axios.put('/api/schedule/' + param.id, param, { headers : headerdata }).then( (response) => {
  // console.log("response : " + JSON.stringify(response.data));
  return response;
 })
 .catch((err) => {
  // console.log(err);
  throw err;
 });
}

async function delete_schedule(headerdata, param) {
 return await axios.delete('/api/schedule/' + param.id, { headers : headerdata }).then( (response) => {
  // console.log("response : " + JSON.stringify(response.data));
  return response;
 })
 .catch((err) => {
  // console.log(err);
  throw err;
 });
}

const Schedule = (props) => {

  const [events, setevents] = useState([]);

  const auth = useAuth();
  const headerdata = {
   "Authorization": "Bearer " + auth.jwtToken
  };

  // イベント追加ダイアログのオープン時は true
  const [isaddopen, setisaddopen] = useState(false);
  // イベント変更/削除ダイアログのオープン時は true
  const [ismoddelopen, setismoddelopen] = useState(false);

  // 選択されたイベントの内容を入れる
  const [selownersub, setselownersub] = useState();
  const [selid, setselid] = useState();
  const [seltitle, setseltitle] = useState();
  const [seltimeflag, setseltimeflag] = useState(false);
  const [selstart, setselstart] = useState();
  const [selend, setselend] = useState();
  const [selplace, setselplace] = useState();
  const [selurl, setselurl] = useState();
  const [selmemo, setselmemo] = useState();
  const [selshare, setselshare] = useState(false);

  useEffect(() => {
      auth.refresh_token().then( (data) => {
       return get_schedule(headerdata);
      }).then( (response) => {
       // alert("response.data.result : " + JSON.stringify(response.data.result));
       let tmp_events=[];
       response.data.result.forEach( (item) =>  {
        let tmp_event = {
         sub : item.sub.S,
         id : Number(item.id.N),
         title : item.title.S,
         timeflag : Number(item.timeflag.N)===1 ? true : false,
         start : item.start.S,
         share : Number(item.share.N)===1 ? true : false
        };
        // alert("tmp_event : " + JSON.stringify(tmp_event));
        if(typeof item.end !== 'undefined' && typeof item.end.S !== 'undefined') {
         tmp_event.end=item.end.S;
        }
        if(typeof item.place !== 'undefined' && typeof item.place.S !== 'undefined') {
         tmp_event.place=item.place.S;
        }
        if(typeof item.url !== 'undefined' && typeof item.url.S !== 'undefined') {
         tmp_event.url=item.url.S;
        }
        if(typeof item.memo !== 'undefined' && typeof item.memo.S !== 'undefined') {
         tmp_event.memo=item.memo.S;
        }
        // alert("tmp_event : " + JSON.stringify(tmp_event));
        tmp_events.push(tmp_event);
       });
       setevents(tmp_events);
      }).catch((err) => {
       if(typeof err.response !== 'undefined' && typeof err.response.status !== 'undefined' && err.response.status === 401) {
        alert("セッションが無効になりました。一度ログアウトして、再度ログインしてください。");
       }
       else {
        alert("エラーのため表示できませんでした。 err : " + JSON.stringify(err));
       }
      });
      // https://zenn.dev/mackay/articles/1e8fcce329336d
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])


  // 日付押下時は予定を追加
  const handleDateClick = useCallback((arg: DateClickArg) => {
    // alert("start : " + arg.dateStr);
    setselstart(arg.dateStr);
    setisaddopen(true);
    if(arg.dateStr.includes('T')){
     setseltimeflag(true);
    }
  }, []);

  // 予定押下時はその予定の変更/削除
  const handleEventClick = useCallback((arg: EventClickArg) => {
    // alert("ownersub : " + arg.event.extendedProps.sub + " timeflag : " + arg.event.extendedProps.timeflag + " start : " + arg.event.start);
    setselownersub(arg.event.extendedProps.sub);
    setselid(parseInt(arg.event.id,10));
    setseltitle(arg.event.title);
    setseltimeflag(arg.event.extendedProps.timeflag);
    setselstart(arg.event.start);
    setselend(arg.event.end);
    setselplace(arg.event.extendedProps.place);
    setselurl(arg.event.url);
    setselmemo(arg.event.extendedProps.memo);
    setselshare(arg.event.extendedProps.share);
    setismoddelopen(true);
  }, []);

  const closeDialog = ( param: any ) => {
   setisaddopen(false);
   setismoddelopen(false);
   setseltimeflag(false);
   setselshare(false);
   if( param && param.method ) {
    if( param.method === "POST" ) {
     // alert("追加の関数実行 " + JSON.stringify(param));

     auth.refresh_token().then( (data) => {
      return post_schedule(headerdata, param)
     }).then( (response) => {
      return get_schedule(headerdata);
     }).then( (response) => {
      let tmp_events=[];
      response.data.result.forEach( (item) =>  {
       let tmp_event = {
        sub : item.sub.S,
        id : Number(item.id.N),
        title : item.title.S,
        timeflag : Number(item.timeflag.N)===1 ? true : false,
        start : item.start.S,
        share : Number(item.share.N)===1 ? true : false
       };
       if(typeof item.end !== 'undefined' && typeof item.end.S !== 'undefined') {
        tmp_event.end=item.end.S;
       }
       if(typeof item.place !== 'undefined' && typeof item.place.S !== 'undefined') {
        tmp_event.place=item.place.S;
       }
       if(typeof item.url !== 'undefined' && typeof item.url.S !== 'undefined') {
        tmp_event.url=item.url.S;
       }
       if(typeof item.memo !== 'undefined' && typeof item.memo.S !== 'undefined') {
        tmp_event.memo=item.memo.S;
       }
       // alert("tmp_event : " + JSON.stringify(tmp_event));
       tmp_events.push(tmp_event);
      });
      setevents(tmp_events);
      alert("イベントの追加が完了しました。");
     }).catch((err) => {
      if(typeof err.response !== 'undefined' && typeof err.response.status !== 'undefined' && err.response.status === 401) {
       alert("セッションが無効になりました。一度ログアウトして、再度ログインしてください。");
      }
      else {
       alert("エラーのため表示できませんでした。 err : " + JSON.stringify(err));
      }
      // console.log("err.config : " + JSON.stringify(err.config));
      // console.log("err.response : " + JSON.stringify(err.response));
     });
    }
    else if( param.method === "PUT" ) {
     // alert("変更の関数実行 " + JSON.stringify(param));
     auth.refresh_token().then( (data) => {
      return put_schedule(headerdata, param)
     }).then( (response) => {
      return get_schedule(headerdata);
     }).then( (response) => {
      let tmp_events=[];
      response.data.result.forEach( (item) =>  {
       let tmp_event = {
        sub : item.sub.S,
        id : Number(item.id.N),
        title : item.title.S,
        timeflag : Number(item.timeflag.N)===1 ? true : false,
        start : item.start.S,
        share : Number(item.share.N)===1 ? true : false
       };
       if(typeof item.end !== 'undefined' && typeof item.end.S !== 'undefined') {
        tmp_event.end=item.end.S;
       }
       if(typeof item.place !== 'undefined' && typeof item.place.S !== 'undefined') {
        tmp_event.place=item.place.S;
       }
       if(typeof item.url !== 'undefined' && typeof item.url.S !== 'undefined') {
        tmp_event.url=item.url.S;
       }
       if(typeof item.memo !== 'undefined' && typeof item.memo.S !== 'undefined') {
        tmp_event.memo=item.memo.S;
       }
       // alert("tmp_event : " + JSON.stringify(tmp_event));
       tmp_events.push(tmp_event);
      });
      setevents(tmp_events);
      alert("イベントの変更が完了しました。");
     }).catch((err) => {
      if(typeof err.response !== 'undefined' && typeof err.response.status !== 'undefined' && err.response.status === 401) {
       alert("セッションが無効になりました。一度ログアウトして、再度ログインしてください。");
      }
      else {
       alert("エラーのため表示できませんでした。 err : " + JSON.stringify(err));
      }
      // console.log("err.config : " + JSON.stringify(err.config));
      // console.log("err.response : " + JSON.stringify(err.response));
     });
    }
    else if( param.method === "DELETE" ) {
     // alert("削除の関数実行 " + JSON.stringify(param));

     auth.refresh_token().then( (data) => {
      return delete_schedule(headerdata, param);
     }).then( (response) => {
      return get_schedule(headerdata);
     }).then( (response) => {
      let tmp_events=[];
      response.data.result.forEach( (item) =>  {
       let tmp_event = {
        sub : item.sub.S,
        id : Number(item.id.N),
        title : item.title.S,
        timeflag : Number(item.timeflag.N)===1 ? true : false,
        start : item.start.S,
        share : Number(item.share.N)===1 ? true : false
       };
       if(typeof item.end !== 'undefined' && typeof item.end.S !== 'undefined') {
        tmp_event.end=item.end.S;
       }
       if(typeof item.place !== 'undefined' && typeof item.place.S !== 'undefined') {
        tmp_event.place=item.place.S;
       }
       if(typeof item.url !== 'undefined' && typeof item.url.S !== 'undefined') {
        tmp_event.url=item.url.S;
       }
       if(typeof item.memo !== 'undefined' && typeof item.memo.S !== 'undefined') {
        tmp_event.memo=item.memo.S;
       }
       // alert("tmp_event : " + JSON.stringify(tmp_event));
       tmp_events.push(tmp_event);
      });
      setevents(tmp_events);
      alert("イベントの削除が完了しました。");
     }).catch((err) => {
      if(typeof err.response !== 'undefined' && typeof err.response.status !== 'undefined' && err.response.status === 401) {
       alert("セッションが無効になりました。一度ログアウトして、再度ログインしてください。");
      }
      else {
       alert("エラーのため表示できませんでした。 err : " + JSON.stringify(err));
      }
      // console.log("err.config : " + JSON.stringify(err.config));
      // console.log("err.response : " + JSON.stringify(err.response));
     });
    }
   }
  }

  return (
    <div>
      <ScheduleEventDialog isOpen={isaddopen} onClose={closeDialog} action='add' my_sub={auth.sub} timeflag={seltimeflag} start={selstart} />
      <ScheduleEventDialog isOpen={ismoddelopen} onClose={closeDialog} action='moddel' my_sub={auth.sub} owner_sub={selownersub} id={selid} title={seltitle} timeflag={seltimeflag} start={selstart} end={selend} place={selplace} url={selurl} memo={selmemo} share={selshare} />
      <p>{auth.email}(ID : {auth.sub} )のスケジュール</p>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]} // 追加
        initialView="dayGridMonth"
        locales={[jaLocale]}
        locale='ja'
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek listWeek', // 追加
        }}
        dateClick={handleDateClick}
        editable={true}
        eventClick={handleEventClick}
        eventContent={renderEventContent}
        selectable="true"
        select={props.selectDateByDragAndDrop}
        events={events}

      />
      <div>
       <Link to={`/`}>ホームに戻る</Link>
      </div>
    </div>
  );
}

export default Schedule;

