import axios from "axios";
import { Link } from "react-router-dom";
import {useState,useRef,useEffect} from 'react';
import {DndContext} from '@dnd-kit/core';
import PrivateRoute from './PrivateRoute';
import { useAuth } from './use-auth';

import { BookmarkRecursive } from './BookmarkRecursive';

export async function get_bookmark(headerdata) {
 // const httpsAgent = new https.Agent({ rejectUnauthorized: false });
 return await axios.get('/api/bookmark', { headers : headerdata }).then( (response) => {
  // console.log("response : " + JSON.stringify(response.data));
  return response;
 })
 .catch((err) => {
  // console.log(err);
  throw err;
 });
}

export async function post_bookmark(headerdata, param) {
 return await axios.post('/api/bookmark', param, { headers : headerdata }).then( (response) => {
  // console.log("response : " + JSON.stringify(response.data));
  return response;
 })
 .catch((err) => {
  // console.log(err);
  throw err;
 });
}

export async function put_bookmark(headerdata, param) {
 return await axios.put('/api/bookmark/' + param.id, param, { headers : headerdata }).then( (response) => {
  // console.log("response : " + JSON.stringify(response.data));
  return response;
 })
 .catch((err) => {
  // console.log(err);
  throw err;
 });
}

export async function delete_bookmark(headerdata, param) {
 return await axios.delete('/api/bookmark/' + param.id, { headers : headerdata }).then( (response) => {
  // console.log("response : " + JSON.stringify(response.data));
  return response;
 })
 .catch((err) => {
  // console.log(err);
  throw err;
 });
}

const Bookmark = () => {
    const [bookmark, setbookmark] = useState([]);
    const [dndparent, setdndparent] = useState(null);

    const auth = useAuth();
    const headerdata = {
     "Authorization": "Bearer " + auth.jwtToken
    };

    useEffect(() => {
      // alert("bookmark");
      auth.refresh_token().then( (data) => {
       return get_bookmark(headerdata);
      }).then( (response) => {
        // alert("setbookmark start.");
        // console.log("response.data.result : " + JSON.stringify(response.data.result));
        setbookmark(response.data.result);
        // alert("setbookmark end.");
      }).catch((err) => {
        if(err.response.status === 401) {
         alert("セッションが無効になりました。一度ログアウトして、再度ログインしてください。");
        }
        else {
         alert("エラーのため表示できませんでした。");
        }
      });
      // https://zenn.dev/mackay/articles/1e8fcce329336d
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    // BookmarkRecursive ・ BookmarkItem 内でボタンが押された時に動作
    const childclick = (data) => {
     // alert("childclick data : " + data);
     const param = JSON.parse(data);
     if( param.method === "POST" ) {
      // if( param.type == "dir" ) {
       // alert("フォルダ追加(POST) type : " + param.type + " 名前(name) : " + param.name + " 親のID(parentid) : " + param.parentid);
      // }
      // else {
       // alert("URL追加(POST) type : " + param.type + " 名前(name) : " + param.name + " url : " + param.url + " 親のID(parentid) : " + param.parentid);
      // }
      auth.refresh_token().then( (data) => {
       return post_bookmark(headerdata, param)
      }).then( (response) => {
       return get_bookmark(headerdata);
      }).then( (response) => {
       setbookmark(response.data.result);
      }).catch((err) => {
       if(err.response.status === 401) {
        alert("セッションが無効になりました。一度ログアウトして、再度ログインしてください。");
       }
       else {
        alert("エラーのため登録できませんでした。");
       }
       // console.log("err.config : " + JSON.stringify(err.config));
       // console.log("err.response : " + JSON.stringify(err.response));
      });
     }
     else if( param.method === "PUT" ) {
      // if( param.type == "url" ) {
      //  alert("変更(PUT) ID : " + param.id + " type : " + param.type + " 名前(name) : " + param.name + " URL : " + param.url);
      // }
      // else {
      //  alert("変更(PUT) ID : " + param.id + " type : " + param.type + " 名前(name) : " + param.name);
      // }
      auth.refresh_token().then( (data) => {
       return put_bookmark(headerdata, param);
      }).then( (response) => {
       return get_bookmark(headerdata);
      }).then( (response) => {
       setbookmark(response.data.result);
      }).catch((err) => {
       if(err.response.status === 401) {
        alert("セッションが無効になりました。一度ログアウトして、再度ログインしてください。");
       }
       else {
        alert("エラーのため変更できませんでした。");
       }
       // console.log("err.config : " + JSON.stringify(err.config));
       // console.log("err.response : " + JSON.stringify(err.response));
      });
     }
     else if( param.method === "DELETE" ) {
      // alert("削除(DELETE) type : " + param.type + " ID : " + param.id);
      auth.refresh_token().then( (data) => {
       return delete_bookmark(headerdata, param);
      }).then( (response) => {
       return get_bookmark(headerdata);
      }).then( (response) => {
       setbookmark(response.data.result);
      }).catch((err) => {
       if(err.response.status === 401) {
        alert("セッションが無効になりました。一度ログアウトして、再度ログインしてください。");
       }
       else {
        alert("エラーのため削除できませんでした。");
       }
       // console.log("err.config : " + JSON.stringify(err.config));
       // console.log("err.response : " + JSON.stringify(err.response));
      });
     }
    }

    function handleDragEnd(event) {
     const { active, over } = event;

     // ドラッグしたアイテム(active)の parentid と numindir を検索する 
     const dragitem = bookmark.find( (each) => {
      return each.id.N == active.id;
     });
     // alert("active : " + JSON.stringify(active) + " over : " + JSON.stringify(over) + " dragitem : " + JSON.stringify(dragitem));

     if( active.id !== over.id ) {
      // ドロップできる領域にドロップした時、親としてidをセット
      // それ以外はnullにする
      setdndparent(over ? over.id : null);
      const overitem = bookmark.find((item) => {
       return item.id.N == over.id;
      });
      if( overitem.type.S == 'dir' ) {
       // alert("active.id(ドラッグしたリソースのid) : " + active.id + " over.id(ドロップした場所にあったリソースのid) : " + over.id + " overname(ドロップした場所のフォルダ名) : " + overitem.name.S);
       const param = {
        method : "PUT",
        id : active.id,
        from_parentid : dragitem.parentid.N,
        from_numindir : dragitem.numindir.N,
        to_parentid : over.id
       };
       // alert("変更(PUT) ID(active.id) : " + param.id + " 移動先のフォルダのID(over.id) : " + param.parentid);
       auth.refresh_token().then( (data) => {
        return put_bookmark(headerdata, param);
       }).then( (response) => {
        return get_bookmark(headerdata);
       }).then( (response) => {
        setbookmark(response.data.result);
       }).catch((err) => {
        if(err.response.status === 401) {
         alert("セッションが無効になりました。一度ログアウトして、再度ログインしてください。");
        }
        else if(err.response.status === 508) {
         alert("移動先を自身または自身の配下とすることはできません。");
        }
        else {
         alert("エラーのため変更できませんでした。");
        }
        // console.log("err.config : " + JSON.stringify(err.config));
        // console.log("err.response : " + JSON.stringify(err.response));
       });
      }
      else if(overitem.type.S == 'url' ) {
       // alert("active.id(ドラッグしたリソースのid) : " + active.id + " over.id(ドロップした場所にあったリソースのid) : " + over.id + " overname(ドロップした場所のページ名) : " + overitem.name.S);
       const param = {
        method : "PUT",
        id : active.id,
        from_parentid : dragitem.parentid.N,
        from_numindir : dragitem.numindir.N,
        to_parentid : overitem.parentid.N,
        to_numindir : overitem.numindir.N
       };
       // alert("変更(PUT) ID(active.id) : " + param.id + " 移動先のフォルダのID(overitem.parentid.N) : " + param.parentid + " 順番(overitem.numindir.N) : " + param.numindir);
       auth.refresh_token().then( (data) => {
        return put_bookmark(headerdata, param);
       }).then( (response) => {
        return get_bookmark(headerdata);
       }).then( (response) => {
        setbookmark(response.data.result);
       }).catch((err) => {
        if(err.response.status === 401) {
         alert("セッションが無効になりました。一度ログアウトして、再度ログインしてください。");
        }
        else if(err.response.status === 508) {
         alert("移動先を自身または自身の配下とすることはできません。");
        }
        else {
         alert("エラーのため変更できませんでした。");
        }
        // console.log("err.config : " + JSON.stringify(err.config));
        // console.log("err.response : " + JSON.stringify(err.response));
       });
      }
      else {
       // console.log("overitem : " + JSON.stringify(overitem));
       // alert("overitem : " + JSON.stringify(overitem));
       alert("ドラッグ先が範囲外です。");
      }
     }
    }


  return (
    <PrivateRoute>
      <h1>ブックマーク</h1>
      <div>
        <p>ブックマーク</p>
        <DndContext onDragEnd={handleDragEnd}>
          <BookmarkRecursive rootid={0} list={bookmark} dndparent={dndparent} childclick={childclick} />
        </DndContext>


      </div>
      <div>
        <Link to={`/`}>ホームに戻る</Link>
      </div>
    </PrivateRoute>
  );
};

//        {bookmark.map( (each_bookmark) => (
//        <p>id : {each_bookmark.id} name : {each_bookmark.name}</p>
//        ))}

export default Bookmark;


