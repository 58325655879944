import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './use-auth';

export function SignIn() {
  const auth = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const executeSignIn = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const result = await auth.signIn(email, password);
    console.log("auth result : " + JSON.stringify(result));
    console.log("auth : " + JSON.stringify(auth));
    if (result.success) {
      navigate({ pathname: '/PrivateTop' });
    } else {
     if( result.challenge ) {
      navigate({ pathname: '/ChangePassword' });
     }
     else {
      alert(result.message);
     }
    }
  };

  return (
   <div>
    <form noValidate onSubmit={executeSignIn}>
      <div>
        <label htmlFor="email">メールアドレス: </label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div>
        <label htmlFor="password">パスワード: </label>
        <input
          id="password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <button type="submit">ログイン</button>
    </form>
    <p>Version 202503.5.3</p>
   </div>
  );
}

