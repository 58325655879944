import * as React from 'react';
import ReactModal from 'react-modal';
import './ScheduleEventDialog.css';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ja } from "date-fns/locale/ja";
import { format } from 'date-fns';
import { TZDate } from "@date-fns/tz";

//日本語化 https://qiita.com/huntas0624/items/78f61cf1e604b15cae49
registerLocale("ja", ja);

// 日付のフォーマットチェック https://ryotah.hatenablog.com/entry/2018/08/31/140100
const ISO8601_DATE_REGEX = /^\d{4}-?\d\d-?\d\d(?:T\d\d(?::?\d\d(?::?\d\d(?:\.\d+)?)?)?(?:Z|[+-]\d\d:?\d\d)?)?$/;

interface Props {
  /** このダイアログを表示するなら true */
  isOpen: boolean;
  // ログイン者の sub
  my_sub: string;
  // 追加ダイアログの場合は add 、変更/削除ダイアログの場合は moddel
  action: string;

  // 以下 moddel の場合、選択されたイベントの内容
  // イベント登録者の sub
  owner_sub: string;
  // ID
  id: number;
  title: string;
  // 時刻まで設定する場合は true
  timeflag: boolean;
  // https://qiita.com/koakirado/items/598a1144372bfb1af465
  start: string;
  end: string | undefined | null;
  place: string;
  url: string;
  memo: string;
  // true の場合は全員に共有、 false の場合は共有しない
  share: boolean;
  /** このダイアログを閉じるときのコールバック */
  onClose?: (param: any) => void;
}

interface State {
  title: string;
  start: TZDate;
  end: TZDate | undefined | null;
  place: string;
  url: string;
  memo: string;
  message: string;
  // 時刻まで設定する場合は true
  timeflag: boolean;
  // 終了日時を設定する場合は true
  end_flag: boolean;
  // true の場合は全員に共有、 false の場合は共有しない
  share: boolean;
  // 他者登録のイベントの場合は true
  disable_moddel_flag: boolean;
}

interface HTMLButtonEvent extends Event {
  target: HTMLButtonElement;
}

export class ScheduleEventDialog extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      title: '',
      start: new TZDate(),
      end: null,
      place : '',
      url : '',
      memo : '',
      message : '',
      timeflag : false,
      end_flag : false,
      share : false,
      disable_moddel_flag : false
    };

    // 具体的に #root 要素などを指定した方がよい？
    ReactModal.setAppElement('body');
  }

  public render(): React.ReactNode {

    const messtyle = {
     color: 'red',
     display: 'inline-block',
     _display: 'inline'
    };

        // <p>state_timeflag : {this.state.timeflag} props_timeflag : {this.props.timeflag} start : {this.props.start}</p>
        // <p>自分: {this.props.my_sub}</p>
        // <p>イベント登録者: {this.props.owner_sub}</p>

    return <div>
      <ReactModal
        isOpen={this.props.isOpen}
        onAfterOpen={this.handleOpen}
        onRequestClose={this.handleClose}
        contentLabel="追加/変更"
        className="modal"
        overlayClassName="overlay"
      >
        <p>{ this.props.action === "add" ?
          <span>イベントの追加</span>
         :
          ( this.state.disable_moddel_flag ?
            <span>イベントの確認</span>
           :
            <span>イベントの変更/削除</span>
          )
        }</p>
        <div style={messtyle}><p>{this.state.message}</p></div>
        <p>{ this.state.disable_moddel_flag ?
          <span>このイベントは他者が登録し共有されたイベントのため、変更や削除はできません。</span>
         :
          <label><input type="checkbox" checked={this.state.timeflag} onChange={this.handleChangetimeflag} />時刻まで設定する</label>
        }</p>
        <p><label>開始日(時)
         { this.state.disable_moddel_flag ?
           <span>{this.state.start.toISOString()}</span>
          :
           <DatePicker 
            dateFormat="yyyy/MM/dd HH:mm"
            locale="ja"
            selected={this.state.start}
            onChange={date => date ? this.setState({start: new TZDate(date, "Asia/Tokyo")}) : this.setState({start: new TZDate()})}
            showTimeSelect
            timeIntervals={30}/>
         }
        </label></p>
        <p>{ !this.state.disable_moddel_flag &&
          <label><input type="checkbox" checked={this.state.end_flag} onChange={this.handleChangeend_flag} />終了日(時)を設定する</label>
        }</p>
        { this.state.end_flag &&
         <p><label>終了日(時)
          { this.state.disable_moddel_flag ?
             <span>{this.state.end ? this.state.end.toISOString() : ''}</span>
            :
             <DatePicker
              dateFormat="yyyy/MM/dd HH:mm"
              locale="ja"
              selected={this.state.end}
              onChange={date => date ? this.setState({end: new TZDate(date, "Asia/Tokyo")}) : this.setState({end: new TZDate()})}
              showTimeSelect
              timeIntervals={30}/>
          }
         </label></p>
        }
        <p><label>イベント名
         { this.state.disable_moddel_flag ?
           <span>{this.state.title}</span>
          :
           <input type="text" autoFocus value={this.state.title} onChange={this.handleChangetitle}></input>
         }
        </label><button onClick={ () => this.copytitle({ copytimeflag : this.state.timeflag, copystart : this.state.start, copytitle : this.state.title})}>
         { this.state.timeflag && <span>開始時刻と</span> }イベント名をコピー</button></p>
        <p><label>場所
         { this.state.disable_moddel_flag ?
           <span>{this.state.place}</span>
          :
           <input type="text" value={this.state.place} onChange={this.handleChangeplace}></input>
         }
        </label><button onClick={ () => this.copyplace({ copyplace : this.state.place})}>場所をコピー</button></p>
        <p><label>URL
         { this.state.disable_moddel_flag ?
           <span>{this.state.url}</span>
          :
           <input type="text" value={this.state.url} onChange={this.handleChangeurl}></input>
         }
        </label><button onClick={ () => this.copyurl({ copyurl : this.state.url})}>URLをコピー</button></p>
        <p><label>メモ
         { this.state.disable_moddel_flag ?
           <span>{this.state.memo}</span>
          :
           <textarea className="textarea" value={this.state.memo} onChange={this.handleChangememo}></textarea>
         }
        </label><button onClick={ () => this.copymemo({ copymemo : this.state.memo})}>メモをコピー</button></p>
        <p>{ !this.state.disable_moddel_flag &&
          <label><input type="checkbox" checked={this.state.share} onChange={this.handleChangeshare} />このイベントを全員に共有する</label>
        }</p>
        { this.props.action === "add" &&
         ( this.state.end_flag ? 
          <div><button className="exec" onClick={ () => this.handleClose({ method : "POST", title : this.state.title, timeflag : this.state.timeflag, start : this.state.start, end : this.state.end, place : this.state.place, url : this.state.url, memo : this.state.memo, share : this.state.share }) } >イベントを追加する</button></div>
          :
          <div><button className="exec" onClick={ () => this.handleClose({ method : "POST", title : this.state.title, timeflag : this.state.timeflag,start : this.state.start, place : this.state.place, url : this.state.url, memo : this.state.memo, share : this.state.share }) } >イベントを追加する</button></div>
         )
        }
        { this.props.action === "moddel" && this.props.owner_sub === this.props.my_sub &&
         ( this.state.end_flag ?
          <div><button className="exec" onClick={ () => this.handleClose({ method : "PUT", id : this.props.id, title : this.state.title, timeflag : this.state.timeflag, start : this.state.start, end : this.state.end, place : this.state.place, url : this.state.url, memo : this.state.memo, share : this.state.share }) } >変更する</button><button className="exec" onClick={ () => this.handleClose({ method : "DELETE", id : this.props.id })} >このイベントを削除する</button></div>
          :
          <div><button className="exec" onClick={ () => this.handleClose({ method : "PUT", id : this.props.id, title : this.state.title, timeflag : this.state.timeflag, start : this.state.start, place : this.state.place, url : this.state.url, memo : this.state.memo, share : this.state.share }) } >変更する</button><button className="exec" onClick={ () => this.handleClose({ method : "DELETE", id : this.props.id })} >このイベントを削除する</button></div>
         )
        }
        <div><button className="exec" onClick={ () => this.handleClose(null)} >何もしない</button></div>
      </ReactModal>
    </div>;
  }

  // コピー https://blog.usize-tech.com/react-copy-clipboard-button/
  private copytitle = (param: any) => {
   if( param.copytitle ) {
    if( param.copytimeflag && param.copystart ) {
     // https://qiita.com/Naoki_kkc/items/2a29287834c453d23ecf
     global.navigator.clipboard.writeText(param.copystart.getHours() + ":" + param.copystart.getMinutes().toString().padStart(2, '0') + param.copytitle);
     this.setState({message: "開始時刻とイベント名をコピーしました"});
    }
    else {
     global.navigator.clipboard.writeText(param.copytitle);
     this.setState({message: "イベント名をコピーしました"});
    }
   }
   else {
    this.setState({message: "イベント名が入っていません"});
   }
   return;
  }

  private copyplace = (param: any) => {
   if( param.copyplace ) {
    global.navigator.clipboard.writeText(param.copyplace);
    this.setState({message: "場所をコピーしました"});
   }
   else {
    this.setState({message: "場所が入っていません"});
   }
   return;
  }

  private copyurl = (param: any) => {
   if( param.copyurl ) {
    global.navigator.clipboard.writeText(param.copyurl);
    this.setState({message: "URLをコピーしました"});
   }
   else {
    this.setState({message: "URLが入っていません"});
   }
   return;
  }

  private copymemo = (param: any) => {
   if( param.copymemo ) {
    global.navigator.clipboard.writeText(param.copymemo);
    this.setState({message: "メモをコピーしました"});
   }
   else {
    this.setState({message: "メモが入っていません"});
   }
   return;
  }

  // フォームのサブミット時にダイアログを閉じる
  private handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.handleClose(null);
  }

  private handleChangetitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({title: event.target.value})
  }

  // https://stackoverflow.com/questions/70182747/get-react-datepicker-date-value-in-onchange
  // https://github.com/Hacker0x01/react-datepicker/issues/4924
  // https://qiita.com/0xkei10/items/2edc61766a4a5a4aee6b
  // https://stackoverflow.com/questions/54496398/typescript-type-string-undefined-is-not-assignable-to-type-string
  // https://teratail.com/questions/307909
  // private handleChangestart = (date?: Date, event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
  //   // this.setState({start: date?.toLocaleDateString('ja') as string})
  //   this.setState({start: date})
  // }

  // private handleChangeend = (date?: Date | undefined | null, event?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
  //   // this.setState({start: date?.toLocaleDateString('ja') as string})
  //   this.setState({end: date})
  // }

  private handleChangetimeflag = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({timeflag: event.target.checked})
  }

  private handleChangeend_flag = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({end_flag: event.target.checked})
  }

  private handleChangeplace = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({place: event.target.value})
  }

  private handleChangeurl = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({url: event.target.value})
  }

  private handleChangememo = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({memo: event.target.value})
  }

  private handleChangeshare = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({share: event.target.checked})
  }

  // ダイアログが開いたときに呼び出される
  private handleOpen = () => {
    // this.setState({message: "props : " + JSON.stringify(this.props)});
    // ここで設定情報などを読み込む
    this.setState({start: new TZDate(this.props.start, "Asia/Tokyo")});
    this.setState({timeflag: this.props.timeflag});
    if( this.props.action === "moddel" ) {
     this.setState({title: this.props.title});
     if(this.props.end) {
      this.setState({end: new TZDate(this.props.end, "Asia/Tokyo")});
      this.setState({end_flag: true});
     }
     this.setState({place: this.props.place});
     this.setState({url: this.props.url});
     this.setState({memo: this.props.memo});
     this.setState({share: this.props.share});

     // 他者登録のイベントの場合は、変更・削除を禁止する
     if( this.props.owner_sub !== this.props.my_sub ) {
      this.setState({disable_moddel_flag: true});
     }
    }
  }

  // 実行、キャンセル、ダイアログ領域外のクリックや、ESCキーを押したときに呼び出される
  private handleClose = (param: any) => {
    if( param && param.method ) {
     if( param.method === "POST" || param.method === "PUT" ) {
      if( !this.state.title ) {
       this.setState({message: "イベント名が入っていません"});
       return;
      }
      const pattern = /^https?:\/\/[\w/:%#\$&\?\(\)~\.=\+\-]+$/;
      if( this.state.url && ! pattern.test(this.state.url) ) {
       this.setState({message: "URLが正しくありません"});
       return;
      }
      if( param.end && param.start > param.end ) {
       this.setState({message: "終了日(時)が開始日(時)よりも前になっています"});
       return;
      }

      // 日時は文字列にする
      if( param.start ) {
       const now = new TZDate();
       const diff = param.start.getTime() - now.getTime();
       if( diff / 1000 / 60 / 60 / 24  > 365 ) {
        this.setState({message: "1年以上先の設定はできません"});
        return;
       }

       if( param.timeflag ) {
        param.start = param.start.toISOString();
       }
       else {
        // https://www.ey-office.com/blog_archive/2023/04/18/short-code-to-get-todays-date-in-yyyy-mm-dd-format-in-javascript/
        param.start = param.start.toLocaleDateString("ja-JP", { year: "numeric", month: "2-digit", day: "2-digit" }).replaceAll('/', '-');
       }
      }
      else {
       this.setState({message: "開始日(時)が入っていません"});
       return;
      }

      if( param.end ) {
       if( param.timeflag ) {
        param.end = param.end.toISOString();
       }
       else {
        // https://www.ey-office.com/blog_archive/2023/04/18/short-code-to-get-todays-date-in-yyyy-mm-dd-format-in-javascript/
        param.end = param.end.toLocaleDateString("ja-JP", { year: "numeric", month: "2-digit", day: "2-digit" }).replaceAll('/', '-');
       }
      }
     }

     if( param.method === "PUT" || param.method === "DELETE" ) {
      if( this.props.my_sub !== this.props.owner_sub ) {
       this.setState({message: "イベントの登録者が他者なので変更/削除できません"});
       return;
      }
     }
    }
    this.setState({message: ''});
    this.setState({title: ''});
    this.setState({start: new TZDate()});
    this.setState({end: null});
    this.setState({place: ''});
    this.setState({url: ''});
    this.setState({memo: ''});
    this.setState({timeflag: false});
    this.setState({end_flag: false});
    this.setState({share: false});
    this.setState({disable_moddel_flag: false});
    // 親コンポーネントにダイアログを閉じてもらうためのコールバック通知
    this.props.onClose?.(param);
  }

  // スタイルのカスタマイズ
  private customStyles: ReactModal.Styles = {
    // ダイアログ内のスタイル（中央に表示）
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    },
    // 親ウィンドウのスタイル（ちょっと暗くする）
    overlay: {
      background: 'rgba(0, 0, 0, 0.2)'
    }
  }
}

